import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import type {
  Review,
  ReviewsListParams,
  PaginatedMeta,
  PaginatedLinks,
  ReviewAddingData,
} from '~/types/models'

export const useReviewsStore = defineStore('reviews', () => {
  const { $api } = useNuxtApp()

  const list = ref<Review[]>([])
  const meta = ref<PaginatedMeta>()
  const links = ref<PaginatedLinks>()

  function getList(
    payload: ReviewsListParams = {
      page: 1,
      sort_by: 'LikesCount',
      sort_direction: 'desc',
    },
  ) {
    return $api.reviews
      .getReviews(payload)
      .then((data) => {
        list.value = data.data
        meta.value = data.meta
        links.value = data.links
      })
      .catch(() => {
        list.value = []
      })
  }

  function addReview(payload: ReviewAddingData) {
    $api.reviews.addReview(payload).catch(() => {})
  }

  return {
    list,
    meta,
    links,
    getList,
    addReview,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useReviewsStore, import.meta.hot))
}